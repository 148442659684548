@import "main-bx";

*, *::after, *::before {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, ul, p {
    margin: 0;
    padding: 0;
}

.container {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;

    @media (max-width: 1340px) {
        padding: 0 25px;
    }

    @media (max-width: 600px) {
        padding: 0 15px;
    }
}

body {
    font-family: $rubik_font;
    background-color: $black;
    width: 100%;
    max-width: $body_width;
    margin: 0 auto;
    color: $white;
    
}

.main {
    overflow-x: hidden;
    position: relative;
} 

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
}

:focus {
    outline-style: none;
    outline-width: 0 !important;
    outline-color: none !important;
}

input,
button,
textarea {
    background: transparent;
    border: none;
}

img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}