@import "main-bx";

.header {
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding-top: 65px;
    &__logo {
        img {
            width: 100%;
            max-width: 290px;
            height: 52px;

            @media (max-width: 1200px) {
                max-width: 151px;
                height: 27px;
            }
        }
    }

    @media (max-width: 1200px) {
        padding-top: 27px;
    }
}