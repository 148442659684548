@import "main-bx";
.mfp-content .form-modal {
    display: block;

    .mfp-close {
        display: none;
    }
}


.form-modal {
    display: none;
    padding: 60px;
    background-color: $white;
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
    position: relative;
    form.error-validation {
        input {
            border-color: red;
        }
    }
    @media (max-width: 600px) {
        padding: 33px;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 15px;
        cursor: pointer;
        img {
            width: 25px;
            height: 25px;
        }
    }

    &__title {
        font-weight: bold;
        font-size: 20px;
        line-height: 100%;
        text-align: center;

        color: #50565B;
        margin-bottom: 30px;
    }

    input {
        border: 2px solid #EEEEEE;
        border-radius: 2px;
        text-align: center;
        width: 100%;
        padding: 17px 0;

        &::placeholder {
            color: #9DA6AB;
            opacity: 0.7;
        }

        &:checked + .form-modal__checkbox-box::before {
            opacity: 1;
        }
    }

    &__box {
        display: block;
        &:nth-child(1){
            margin-bottom: 18px;
        }
        &:nth-child(2) {
            margin-bottom: 25px;
        }
    }

    &__btn {
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-align: center;
        width: 100%;

        background: #00ACE9;
        border-radius: 2px;
        padding: 17px 0;
        cursor: pointer;
        &:hover {
            background: #1b9ece;
        }

        &:disabled {
            opacity: 0.5;
            cursor: progress;
            background: #00ACE9;
        }
    }

    &__checkbox {
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: 11px;
        line-height: 100%;
        color: #9DA6AB;
        opacity: 0.7;
        padding-top: 20px;
        cursor: pointer;
        &-box {
            width: 16px;
            height: 16px;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            background: #FFFFFF;
            border: 2px solid #EEEEEE;
            border-radius: 2px;
            margin-right: 10px;

            &::before {
                content: "";
                width: 8px;
                height: 8px;
                background-color: $color_blue;
                border-radius: 2px;
                opacity: 0;
            }
        }
    }
}