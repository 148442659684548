@import "main-bx";

.counter {
    &__inner {
        width: 100%;
        max-width: 280px;
        height: 280px;
        position: relative;

        @media (max-width: 768px) {
            margin: auto;
        }
    }

    .progress-circle {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        transform: rotate(-90deg);
    }

    .progress-circle-prog {
        fill: none; 
        stroke: $color_blue;
        stroke-width: 8px;  
        stroke-dasharray: 0 999;    
        stroke-dashoffset: 0px;
        transition: stroke-dasharray 0.7s linear 0s;
        filter: drop-shadow(0px 0px 2px rgba(204, 238, 251, 0.4));
    }

    &__text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        font-weight: 700;
        font-size: 44px;
        line-height: 100%;

        text-align: right;

        color: #FFFFFF;

        .progress-text {
            color: $color_blue;
        }
    }
}