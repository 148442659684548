@import "main-bx";

.mechanics {
    padding: 110px 0 50px;

    @media (max-width: 768px) {
        padding: 40px 0;
    }

    svg {
        width: 20px;
        height: 20px;
    }

    img {
        width: 55px;
        height: 55px;
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: 600px) {
            margin-top: -20px;
        }
    }

    &__item {
        width: 24%;
        height: 250px;
        background: rgba(80, 86, 91, 0.3);
        border-radius: 2px;
        padding: 40px 30px;
        position: relative;

        @media (max-width: 1240px) {
            height: 300px;
        }

        @media (max-width: 1000px) {
            width: 49%;
            margin-bottom: 20px;
        }

        @media (max-width: 600px) {
            width: 100%;
            height: auto;
            display: flex;
            padding: 20px;
        }
    }

    &__top {
        top: 0;
        right: 0;
        position: absolute;
        background-image: url(../img/st.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 45px;
        height: 40px;
        @media (max-width: 600px) {
            transform: rotate(90deg);
            top: auto;
            bottom: 0;
        }
        &_res {
            top: -36px;
            background-image: url(../img/st2.png);
            width: 94px;
            height: 77px;

            @media (max-width: 600px) {
                background-image: url(../img/mst2.png);
                transform: rotate(0);
                width: 64px;
                height: 64px;
            }
        }
    }

    &__numb {
        position: absolute;
        top: -25px;

        font-weight: bold;
        font-size: 60px;
        line-height: 120%;
        color: #9DA6AB;

        @media (max-width: 600px) {
            position: static;
            margin-right: 20px;
            font-size: 40px;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;
        color: $white;
        padding-top: 30px;

        @media (max-width: 600px) {
            padding-top: 0;
            margin-bottom: 34px;
            font-size: 20px;
        }
    }

    &__link {
        position: absolute;
        bottom: 40px;

        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        letter-spacing: 0.05em;
        text-decoration-line: underline;
        text-transform: uppercase;
        color: $white;
        transition: all .2s linear;

        @media (max-width: 600px) {
            position: static;
        }

        svg {
            margin-right: 10px;
            stroke: $color_blue;
        }
        &:hover {
            color: $color_blue;
        }
        &:hover svg {
            @include animationName(bounceIn, .7s, "both")
        }
    }

    &__question {
        position: absolute;
        bottom: 40px;
        
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        letter-spacing: 0.05em;
        text-decoration-line: underline;
        text-transform: uppercase;
        color: #9DA6AB;
        transition: all .2s linear;

        @media (max-width: 600px) {
            position: static;
        }

        &-inner {
            position: relative;
            display: flex;
            align-items: center;
        }

        &-pop {
            position: absolute;
            top: -130px;
            left: -140px;
            width: 300px;
            padding: 16px 30px 30px;

            font-weight: 400;
            font-size: 14px;
            line-height: 120%;
            text-transform: none;
            color: #50565B;

            background: url(../img/prompt.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto;
            z-index: -1;
            opacity: 0;
            transition: all .2s linear .2s;
        }

        a {
            color: #9DA6AB;
            transition: all .2s linear;
        }

        svg {
            margin-right: 10px;
            stroke: #9DA6AB;
            transition: all .2s linear;
        }

        &:hover {
            color: $color_blue;
            .mechanics__question-pop {
                opacity: 1;
                z-index: 100;
            }
            a {
                color: $color_blue;
            }

            svg {
                @include animationName(swing, .7s, "both");
                stroke: $color_blue;
            }
        }
    }
}
