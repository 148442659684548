@keyframes bounceIn {
    0%, 20%, 40%, 60%, 80%, 100% {
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
        opacity: 0;
        transform: scale3d(.3, .3, .3);
    }
    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
        transform: scale3d(.9, .9, .9);
    }
    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
        transform: scale3d(.97, .97, .97);
    }
    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}


@keyframes bounceInLeft {
    0%, 60%, 75%, 90%, 100% {
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
        opacity: 0;
        transform: translate3d(-80px, 0, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(10px, 0, 0);
    }
    75% {
        transform: translate3d(-10px, 0, 0);
    }
    90% {
        transform: translate3d(5px, 0, 0);
    }
    100% {
        transform: none;
    }
} 

@keyframes rubberBand {
    0% {
        transform: scale3d(1, 1, 1);
    }
    30% {
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        transform: scale3d(.95, 1.05, 1);
    }
    75% {
        transform: scale3d(1.05, .95, 1);
    }
    100% {
        transform: scale3d(1, 1, 1);
    }
}

@keyframes swing {
    20% {
        transform: rotate3d(0, 0, 1, 15deg);
    }
    40% {
        transform: rotate3d(0, 0, 1, -10deg);
    }
    60% {
        transform: rotate3d(0, 0, 1, 5deg);
    }
    80% {
        transform: rotate3d(0, 0, 1, -5deg);
    }
    100% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
}
