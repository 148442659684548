@import "main-bx";

.draw-dates {
    background-color: #3c3f42;
    padding: 50px 0;
    @media (max-width: 768px) {
        padding: 40px 0;
    }
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__title {
        font-weight: 500;
        font-size: 32px;
        line-height: 100%;
        text-transform: uppercase;
        color: $white;
        margin-bottom: 25px;

        @media (max-width: 768px) {
            font-size: 24px;
            line-height: 100%;
            margin-bottom: 20px;
        }
    }

    &__steps {
        display: flex;
        background-color: #50565B;
        margin-bottom: 60px;
        width: 100%;
        max-width: 890px;

        @media (max-width: 768px) {
            max-width: 400px;
            margin-bottom: 30px;
        }
        &-item {
            display: flex;
            align-items: center;
            padding: 16px;
            background-color: #50565B;
            border-radius: 2px;
            transition: all .2s linear .5s;

            @media (max-width: 940px) {
                flex:  1 1 auto;
            }

            @media (max-width: 768px) {
                display: none;
                &:nth-child(1),
                &:nth-child(2) {
                    display: flex;
                }

                &.slick-slide {
                    display: flex !important;
                }
            }
            
            &:not(:last-child) {
                margin-right: 8px;
            }

            &.active {
                background-color: #00ACE9;
                backdrop-filter: blur(10px);

                .draw-dates__steps-bold,
                .draw-dates__steps-date {
                    color: $white;
                    transition: all .2s linear;
                }
            }
        }
        &-bold {
            font-weight: bold;
            font-size: 15px;
            line-height: 100%;
            text-transform: uppercase;
            color: rgba(255, 255, 255, 0.4);
            margin-right: 10px;
        }

        &-date {
            font-weight: normal;
            font-size: 15px;
            line-height: 100%;
            color: rgba(255, 255, 255, 0.4);
        }
    }

    &__message {
        font-weight: normal;
        font-size: 28px;
        line-height: 130%;
        color: $white;

        @media (max-width: 768px) {
            font-size: 20px;
            width: 100%;
            max-width: 350px;
            br {
                display: none;
            }
        }

        @media (max-width: 330px) {
            max-width: 280px;
        }
    }
}

@media (max-width: 1200px) {
    .draw-dates {
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
            text-align: center;
            &:last-child {
                margin-top: 30px;
            }
        }
    }
}

@media (max-width: 768px) {
    .draw-dates {
        &__item {
            flex: 1 0 auto;
            display: block;
            text-align: left;
        }
    }
}