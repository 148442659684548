@import "main-bx";

.footer {
    background-color: #50565B;
    &__top {
        padding: 40px 0;
        &-left {
            @media (max-width: 800px) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            a {
                font-weight: 700;
                font-size: 16px;
                line-height: 100%;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: $white;
                @media (max-width: 768px) {
                    font-size: 14px;
                    text-align: center;
                }
                &:first-child {
                    margin-right: 70px;
                    @media (max-width: 800px) {
                        margin-right: 0;
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
    &__bottom {
        padding: 22px 0;
        background: rgba(255, 255, 255, 0.05);
        @media (max-width: 600px) {
            text-align: center;
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
        }
    }
    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 800px) {
            flex-direction: column;
        }
    }

    &__socials {
        display: flex;
        align-items: center;

        @media (max-width: 800px) {
            order: -1;
            margin-bottom: 50px;
        }
        .social__circle {
            background-color: transparent;
            border: 1px solid $white;
            &:not(:last-child) {
                margin-right: 20px;
            }
            &:hover {
                border: 1px solid $color_blue;
            }
        }
    }
}