@import "main-bx";
.prizes__gains {
    display: none;
    position: absolute;
    top: 130px;
    left: 13px;
    right: 0;
    width: 1300px;
    height: 270px;
    z-index: 2000;
    background: url(../img/prompt2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (max-width: 1340px) {
        width: 100%;
        left: 0;
    }
    @media (max-width: 600px) {
        top: -50px;
        background: $white;
        height: auto;
    }

    &-inner {
        padding: 32px 32px 52px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 600px) {
            padding: 20px;
        }
    }
    &-item {
        
        img {
            height: 185px;
            object-fit: contain;

            @media (max-width: 600px) {
                height: 250px;
            }
        }

        &:not(:last-child) {
            margin-right: 20px;

            @media (max-width: 600px) {
                margin-right: 0;
            }
        }
    }

    &-btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 42%;
        left: 0;
        right: 0;
        z-index: 100;
        padding: 0 20px;

        @media (max-width: 600px) {
            top: 45%;
        }
        button {
            width: 30px;
            height: 30px;
            background-color: rgba(0, 0, 0, .5);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        img {
            width: 20px;
            height: 20px;
        }
    }

    &-prev {
        img {
            transform: rotate(90deg);
        }
    }

    &-next {
        img {
            transform: rotate(-90deg);
        }
    }
}