
$base_fonts: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
$rubik_font: Rubik, $base_fonts;

$default-font-color: #000000;

$black: #2D2D2D;
$white: #FFFFFF;
$color_dark:#50565B;
$color_blue: #00ACE9;
$color_grey: #9DA6AB;

$body_width: 1920px;