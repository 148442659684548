@import "main-bx";
.prizes__megabox {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    position: relative;
    background-image: url(../img/megabox.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 104px 80px 90px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);

    @media (max-width: 600px) {
        max-width: 100%;
        padding: 80px 40px 40px;
    }

    &-img {
        position: absolute;
        top: -70px;
        left: 0;
        right: 0;

        img {
            width: 120px;
            height: 133px;

            @media (max-width: 600px) {
                width: 109px;
                height: 121px;
            }
        }
    }

    &-title {
        font-weight: 700;
        font-size: 26px;
        line-height: 100%;
        text-transform: uppercase;
        margin-bottom: 16px;

        @media (max-width: 600px) {
            font-size: 20px;
        }
    }

    &-subtitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 100%;
        margin-bottom: 25px;
        @media (max-width: 600px) {
            font-size: 14px;
            margin-bottom: 20px;
        }
    }

    &-text {
        font-size: 14px;
        line-height: 21px;
        @media (max-width: 600px) {
            font-size: 12px;
        }
    }
    
}