@import "main-bx";

.sharing {
    padding: 30px 0;
    background-color: #50565B;
    @media (max-width: 768px) {
        padding: 23px 0 43px;
    }
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        color: $white;

        @media (max-width: 1200px) {
            justify-content: space-around;
        }

        @media (max-width: 600px) {
            flex-direction: column;
            align-items: center;
        }
    }

    &__title {
        font-weight: bold;
        font-size: 28px;
        line-height: 130%;

        @media (max-width: 768px) {
            font-size: 20px;
        }

        @media (max-width: 600px) {
            margin-bottom: 25px;
        }
    }

    &__copy {
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        letter-spacing: 0.05em;
        text-decoration-line: underline;
        text-transform: uppercase;
        color: $white;
        @media (max-width: 768px) {
            font-size: 16px;
        }
        &:hover {
            svg {
                @include animationName(rubberBand, .2s, "both")
            }
        }
        
        svg {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }

        &-pop {
            position: absolute;
            top: 30px;
            left: 0;
            right: 0;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 1.5px;
            padding: 7px;
            background-color: #252627;
            border-radius: 5px;
            display: none;
        }
    }

    &__socials {
        display: flex;
        align-items: center;

        @media (max-width: 1200px) {
            width: 100%;
            justify-content: center;
            margin-top: 30px;
        }

        @media (max-width: 600px) {
            flex-wrap: wrap;
            margin-top: -20px;
            margin-left: -20px;
            padding-top: 32px;
        }
    }
}