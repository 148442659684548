@import "main-bx";

.winners {
    padding: 50px 0;
    @media (max-width: 768px) {
        padding: 30px 0 60px;
        
    }
    &__inner {
        position: relative;
    }
    &__title {
        text-align: center;
        font-weight: 700;
        font-size: 48px;
        line-height: 57px;

        color: $white;
        margin-bottom: 30px;

        @media (max-width: 768px) {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 20px;
        }
    }

    &__select {
        padding: 0 20px;
        background-color: $color_blue;
        margin-bottom: 20px;
        display: none;
        .winners__tabs-item {
            flex-direction: row;
            justify-content: flex-start;

            &.active {
                background-color: $color_blue;
            }
        }

        .winners__tabs-date {
            margin-right: 15px;
        }

        @media (max-width: 600px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-icon {
            width: 20px;
            height: 20px;
            transition: all .2s linear;
            &.open {
                transform: rotate(180deg);
            }
        }
    }

    &__tabs {
        display: flex;
        background-color: #50565B;
        border-radius: 2px;
        margin-bottom: 30px;
        @media (max-width: 600px) {
            display: none;
            position: absolute;
            top: 120px;
            left: 0;
            right: 0;
            z-index: 100;
            box-shadow: 0px 0px 10px 0px rgba(255,255,255,0.3);
        }
        
        &.show {
            display: block;
        }

        &-item {
            flex: 1 0 auto;
            height: 68px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.current {
                background-color: #00ACE9;
            }

            &.active {
                background-color: #2DC7FD;

                &.deactive .winners__tabs-date {
                    opacity: 1;
                }
            }

            &.deactive {
                .winners__tabs-date {
                    opacity: 0.3;
                }
            }
        }
        &-date {
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
        }
        &-raffle {
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
        }
    }

    &__future {
        text-align: center;
        font-size: 28px;
        line-height: 32px;

        padding: 20px 0;

        display: none;

        &.show {
            display: block;
        }

        @media (max-width: 600px) {
            font-size: 20px;
        }
    }

    &__users {
        
        display: none;


        padding: 30px 155px 30px 112px;
        background-color: #50565B;
        border-radius: 2px;

        @media (max-width: 600px) {
            padding: 20px;
        }

        &.active {
            display: block;
        }

        &-item {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            color: $white;
            
            &:not(:last-child) {
                margin-bottom: 48px;

                @media (max-width: 600px) {
                    margin-bottom: 25px;
                }
            }
        }

        &-date,
        &-name,
        &-phone {
            font-weight: normal;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
        }
        &-date,
        &-name {
            
            @media (max-width: 600px) {
                width: 50%;
                text-align: left;
                font-size: 16px;
            }
        }
        &-phone {
            font-weight: 700;

            @media (max-width: 600px) {
                width: 100%;
                text-align: right;
                margin-top: 20px;
                font-size: 16px;
            }
        }
    }
}