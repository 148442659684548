@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik/Rubik-Regular.woff2') format('woff2'),
        url('../fonts/Rubik/Rubik-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik/Rubik-Medium.woff2') format('woff2'),
        url('../fonts/Rubik/Rubik-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik/Rubik-Bold.woff2') format('woff2'),
        url('../fonts/Rubik/Rubik-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}