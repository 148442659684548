@import "main-bx";

.social {
    width: 50px;
    height: 50px;
    &:not(:last-child) {
        margin-right: 20px;

        @media (max-width: 600px)  {
            margin-right: 0;
        }
    }

    @media (max-width: 600px) {
        margin-top: 20px;
        margin-left: 20px;

        width: 48px;
        height: 48px;
    }

    &__circle {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.1);
        transition: all .2s linear .2s;
        &:hover {
            background-color: #2DC6FD;

            .social__icon {
                @include animationName(bounceIn, .5s, "both")
            }
        }
    }

    &__icon {
        &_fb {
            width: 10px;
            height: 20px;
        }

        &_inst {
            width: 19px;
            height: 19px;
        }

        &_vk {
            width: 24px;
            height: 14px;
        }

        &_ok {
            width: 14px;
            height: 23px;
        }

        &_tel {
            width: 23px;
            height: 20px;
        }

        &_tw {
            width: 23px;
            height: 20px;
        }

        &_wht {
            width: 25px;
            height: 25px;
        }

        &_ph {
            width: 22px;
            height: 22px;
        }
    }
}

