@import "main-bx";
.prizes__item {
    width: 24.7%;
    height: 430px;
    padding: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    @media (max-width: 1100px) {
        width: 49.6%;
        margin-bottom: 20px;
    }

    @media (max-width: 600px) {
        width: 100%;
        height: auto;
        padding: 30px 40px;
        margin-bottom: 12px;
    }

    &-inner {
        position: sticky;
        z-index: 100;
        width: 100%;
        height: 100%;
        text-align: center;
        padding-top: 100px;

        @media (max-width: 600px) {
            padding-top: 80px;
        }
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: rgba(52, 52, 52, 0.8);
        border: 2px solid #333333;
        box-sizing: border-box;
        backdrop-filter: blur(70px);
        border-radius: 2px;
    }

    &_open {
        &::before {
            content: none;
        }

        .prizes__step,
        .prizes__boxes,
        .prizes__text {
            color: $white;
        }
    }
    &_one {
        background-image: url(../img/step/01.png);
    }
    &_two {
        background-image: url(../img/step/02.png);
    }
    &_three {
        background-image: url(../img/step/03.png);
    }
    &_four {
        background-image: url(../img/step/04.png);
    }
}