@import "main-bx";


.prizes {
    padding: 50px 0;

    @media (max-width: 768px) {
        padding: 10px 0 30px;
    }

    &__title {
        font-weight: bold;
        font-size: 48px;
        line-height: 57px;
        text-align: center;
        color: $white;

        padding-bottom: 50px;
        @media (max-width: 768px) {
            font-size: 24px;
            line-height: 28px;
            padding-bottom: 32px;
        }
    }
    &__inner {
        position: relative;
    }
    &__wrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 120px;
        @media (max-width: 600px) {
            margin-bottom: 80px;
        }
    }

    &__top {
        position: absolute;
        top: 0;
        left: 0;

        svg {
            width: 37px;
            height: 37px;
        }

        @media (max-width: 600px) {
            top: -10px;
            left: -20px;
        }
    }

    &__step {
        font-weight: 700;
        font-size: 26px;
        line-height: 100%;
        text-align: center;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.6);
        margin-bottom: 16px;

        @media (max-width: 600px) {
            font-size: 20px;
        }
    }

    &__boxes {
        font-weight: 400;
        font-size: 18px;
        line-height: 100%;
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
        margin-bottom: 24px;

        @media (max-width: 600px) {
            font-size: 14px;
        }
    }
    
    &__text {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
        opacity: 0.8;

        @media (max-width: 600px) {
            font-size: 12px;
            margin-bottom: 40px;
        }
    }


    &__bottom {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        svg {
            width: 90px;
            height: 90px;
        }

        @media (max-width: 600px) {
            position: static;
        }
    }
    
    &__all {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: 600px) {
            position: static;
            
           
        }
        
        &-btn {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #00ACE9;
            border-radius: 50%;
            cursor: pointer;
            margin-bottom: 16px;
            transition: all .2s linear;
            @media (max-width: 768px) {
                width: 40px;
                height: 40px;
            }

            svg {
                width: 28px;
                height: 28px;
                stroke: #fff;
                fill: #fff;
                transition: all .2s linear;
            }

            &:hover {
                background-color: #2DC6FD;
            }

            &.close {
                background-color: #fff;

                svg {
                    transform: rotate(-45deg);
                    stroke: #00ACE9;
                }
            }
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color: $color_blue;
        }
    }

    &__button {
        margin-top: 60px;
        display: flex;
        justify-content: center;

        @media (max-width: 400px) {
            .btn_go,
            .btn__text {
                width: 100%;
            }
        }
    }
}

@import "./prizes__item.scss";
@import "./prizes__megabox.scss";
@import "./prizes__gains.scss";