@import "main-bx";

.intro {
    position: relative;
    &__content {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        left: 0;

        @media (max-width: 1000px) {
            padding-top: 100px;
        }

        @media (max-width: 768px) {
            position: static;
            top: auto;
            left: auto;
            right: auto;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: 100%;

        @media (max-width: 768px) {
            height: 468px;
            padding: 12px 0 60px;
            justify-content: flex-end;
        }
    }

    &__img {
        img {
            object-fit: contain;
        }
        @media (max-width: 1000px) {
            height: 500px;
        }

        @media (max-width: 768px) {
            display: none;
        }

        &_mobile {
            display: none;
            position: absolute;
            top: -110px;
            right: -110px;
            @media (max-width: 768px) {
                display: block;
            }   
        }
    }

    &__title {
        margin-bottom: 32px;
        @media (max-width: 768px) {
            margin-bottom: 20px;
        }
        h1 {
            font-weight: bold;
            font-size: 60px;
            line-height: 100%;
            color: $white;

            @media (max-width: 768px) {
                font-size: 26px;
                line-height: 100%;
            }
        }

        span {
            color: $color_blue;

            @media (max-width: 768px) {
                color: $white;
            }
        }
    }

    &__text {
        font-size: 28px;
        line-height: 120%;
        color: $white;
        opacity: 0.7;
        margin-bottom: 50px;
        @media (max-width: 768px) {
            font-size: 16px;
            margin-bottom: 25px;
        }
    }

    &__anchor {
        position: absolute;
        bottom: 100px;
        width: 100%;
        display: flex;
        justify-content: center;

        @media (max-width: 1000px) {
            bottom: 30px;
        }

        @media (max-width: 768px) {
            display: none;
        }
        &-box {
            width: 50px;
            height: 50px;
            display: block;
            cursor: pointer;
            &:hover span {
                border-right: 3px solid #2DC7FD;
                border-bottom: 3px solid #2DC7FD;
            }

            span {
                width: 20px;
                height: 20px;
                display: block;
                border-right: 3px solid #00ACE9;
                border-bottom: 3px solid #00ACE9;
                transform: rotate(45deg);
                margin: -7px;
                animation: animate 2s infinite;
                transition: all .2s linear;
            }

            span:nth-child(2) {
                animation-delay: -0.2s;
            }

            span:nth-child(3) {
                animation-delay: -0.4s;
            }
        }
    }
}

@keyframes animate {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
		transform: rotate(45deg) translate(10px, 10px);
	}
	100% {
		opacity: 0;
	}
}