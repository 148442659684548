@import "main-bx";

.btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $white;
    transition: all .2s linear;
    padding: 0;

    font-weight: 500;
    font-size: 18px;
    line-height: 100%;

    letter-spacing: 0.05em;
    text-transform: uppercase;

    @media (max-width: 769px) {
        font-size: 16px;
    }

    &_go {
        border: 2px solid #00ACE9;
        height: 64px;
        @media (max-width: 769px) {
            height: 48px;
        }
        .btn__icon {
            width: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            @media (max-width: 769px) {
                width: 48px;
            }
        }

        svg {
            width: 24px;
            height: 24px;
            stroke: $white;
        }

        .btn__text  {
            display: flex;
            align-items: center;
            padding: 0 32px;
            height: 100%;
            background-color: #00ACE9;
            transition: all .2s linear;
        }

        &:hover {
            border: 2px solid #2DC7FD;
            .btn__text {
                background-color: #2DC7FD;
            }

            svg {
                @include animationName(bounceInLeft, .3s, "both")
            }
        }
    }
}